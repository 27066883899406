<template>
  <button-basic
    v-if="is_active"
    outlined
    class="inline-flex justify-center items-center w-11.5 rounded-bl-none bg-transparent transition ease-in-out duration-200"
    :aria-disabled="disabled"
    :aria-label="t('previous')"
    :disabled="disabled"
    :class="{ 'opacity-40': disabled, 'hover:bg-sable': !disabled, 'cursor-not-allowed': disabled }"
    @click.prevent="previous"
  >
    <img src="~/assets/svg/arrow-left.svg?inline" :alt="t('next')" />
  </button-basic>
</template>

<script>
import ButtonBasic from "~/components/Buttons/ButtonBasic.vue";

export default {
  name: "SliderControls",
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();

    return { t, locale, localePath };
  },
  components: {
    ButtonBasic,
  },
  props: {
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slider: null,
      is_active: false,
      disabled: false,
    };
  },
  watch: {
    disabled(value) {
      this.$emit("disabled", value);
    },
  },
  mounted() {
    this.slider = document.getElementById(this.target);
    this.slider.addEventListener("scroll", this.compute_width);
    window.addEventListener("resize", this.compute_width, true);
    this.compute_width();
  },
  methods: {
    compute_width() {
      const outerWidth = this.slider.offsetWidth;
      if (!this.slider.firstChild) return;

      const innerWidth = this.slider.firstChild.offsetWidth;
      this.is_active = outerWidth <= innerWidth;

      this.disabled = this.slider.scrollLeft <= 0;
    },
    previous() {
      const left = document.getElementById(this.target).scrollLeft;
      const slides = this.slider.getElementsByClassName("card");
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const padding = vw >= 1024 ? 112 : 0;

      for (let i = slides.length - 1; i >= 0; i--) {
        if (left > slides[i].offsetLeft) {
          this.slider.scrollTo({
            left: slides[i].offsetLeft - padding,
            behavior: "smooth",
          });
          break;
        }
      }
    },
  },
};
</script>

<style scoped></style>

<i18n lang="json">
{
  "fr": {
    "next": "Suivant",
    "previous": "Précédent"
  },
  "en": {
    "next": "Next",
    "previous": "Previous"
  }
}
</i18n>
