<template>
  <div
    class="absolute top-0 w-18 lg:w-28 z-10 from-white h-full pointer-events-none"
    :class="hidden ? '' : 'md:block'"
  ></div>
</template>

<script>
export default {
  name: "SliderOverlay",
  props: {
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hidden: false,
    };
  },
  mounted() {
    this.slider = document.getElementById(this.target);
    this.slider.addEventListener("scroll", this.compute_width);
    window.addEventListener("resize", this.compute_width, true);
    this.compute_width();
  },
  methods: {
    compute_width() {
      const outerWidth = this.slider.offsetWidth;
      if (!this.slider.firstChild) return;

      const innerWidth = this.slider.firstChild.offsetWidth;
      this.hidden = outerWidth > innerWidth;
    },
  },
};
</script>
